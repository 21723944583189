try {
    window.$ = window.jQuery = require('jquery');

    jQuery.event.special.touchstart = {
        setup: function( _, ns, handle ) {
            this.addEventListener("touchstart", handle, { passive: !ns.includes("noPreventDefault") });
        }
    };
    jQuery.event.special.touchmove = {
        setup: function( _, ns, handle ) {
            this.addEventListener("touchmove", handle, { passive: !ns.includes("noPreventDefault") });
        }
    };
    jQuery.event.special.wheel = {
        setup: function( _, ns, handle ){
            this.addEventListener("wheel", handle, { passive: true });
        }
    };
    jQuery.event.special.mousewheel = {
        setup: function( _, ns, handle ){
            this.addEventListener("mousewheel", handle, { passive: true });
        }
    };

    window.Popper = require('@popperjs/core/dist/umd/popper.js').default;

    window.bootstrap = require('bootstrap');


} catch (e) {}

require('jquery.mmenu');
require('owl.carousel');
require('slider-pro');
require('magnific-popup');
require('theia-sticky-sidebar');
require('css-element-queries/src/ResizeSensor')
require('sticky-kit/dist/sticky-kit');
require('jquery-nice-select');